.nav-bar {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  color: white;
}

.nav-bar h3 {
    font-weight: normal;
    font-size: 28px;
    margin-left: 20px;
}

.nav-bar__links {
  list-style: none;
  display: flex;
  font-size: 20px;
}

.link {
  margin-right: 20px;
  color: white;
  text-decoration: none;
}

.active {
  font-weight: bold;
}
