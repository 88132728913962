.portfolio-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(243, 240, 240);
    margin: 10px 20px;
    padding: 12px;
}

.portfolio-card h3 {
    margin: 20px 0;
}

.portfolio-card p {
    font-size: 18px;
    margin-bottom: 15px;
}


