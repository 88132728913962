table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 2rem;
}

thead th {
  background-color: #f2f2f2;
  padding: 10px;
}

tbody td {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

tbody tr:last-child td {
  border-bottom: none;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.arrows {
  display: flex;
  flex-direction: column;
  font-style: bold;
}
