.experience-card {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  background-color: rgb(243, 240, 240);
  margin: 10px 20px;
  padding: 12px;
}

.experience-card h3 {
  font-size: 28px;
  margin-top: 20px;
}

.experience-card h4 {
  font-weight: lighter;
  font-size: 22px;
  margin: 20px;
}

.experience-card p {
  margin-top: 10px;
  font-size: 20px;
}

.experience-card img {
  height: 120px;
  width: auto;
  border-radius: 10%;
}
