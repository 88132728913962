.main {
    display: flex;
    margin-top: 3rem;
    margin-bottom: 0;
}

.intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}

h1 {
    font-weight: normal;
    font-size: 48px;
}

.about-me h2 {
    margin-top: 0;
}

h3 {
    margin: 0;
    text-align: center;
}

p {
    font-size: 24px;
    line-height: 1.5;
    margin: 0 20px;
    text-align: center;
}

.about-me p {
    margin: 0 6rem 10px 5rem;
    font-size: 18px;
}

img {
    width: 450px;
    height: 450px;
    border-radius: 50%;
    margin: 0 7rem;
}

.contact-links {
    width: 35%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}