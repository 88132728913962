body {
  margin: 0;
}

h2 {
  font-size: 42px;
  font-weight: normal;
  margin: 30px 6rem;
  text-align: left;
}

hr {
  width: 95%;
  margin-bottom: 30px;
}